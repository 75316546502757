<template>
  <!-- table -->
  <div>
    <p id="table-caption" class="sr-only">{{ tbTitle }}</p>
    <p id="table-summary" class="sr-only">{{ tbSummary }}</p>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
      }"
      :select-options="selectYn"
      :total-rows="totalRows"
      @on-selected-rows-change="onRowClick"
      aria-labelledby="table-caption"
    >
      <template slot="table-row" slot-scope="props">
        
        <!-- Column: mngrId -->
        <span v-if="props.column.field === 'mngrId'">
          <a @click="goDtl(props)">{{ props.row.mngrId }}</a>
        </span>

        <!-- Column: natTl (공지사항 제목) -->
        <span v-else-if="props.column.field === 'natTl'">
          <a @click="goDtl(props)">{{ props.row.natTl }}</a>
        </span>
        <!-- Column: hnetFrmSn (홈넷사 관리) -->
        <span v-else-if="props.column.field === 'hnetFrmSn'">
          <a @click="goDtl(props)">{{ props.row.hnetFrmSn }}</a>
        </span>
        <!-- Column: hnetFrmSn (홈넷사 관리) -->
        <span v-else-if="props.column.field === 'frmNm'">
          <a @click="goDtl(props)">{{ props.row.frmNm }}</a>
        </span>
        <!-- 테이블에 내역보기가 있을경우 -->
        <span v-else-if="props.column.field === 'hstId'">
          {{ props.row.mngrId }}
        </span>
        <!-- 클라우드 운영보고서 다운로드 링크 추가 -->
        <span v-else-if="props.column.field === 'otxtFlNm'">
          <!--          <a
            :href="`/api/v1/mngr/report/cloud-operation/attach-file/${props.row.stndYm}`"
          > {{ props.row.otxtFlNm }}</a>-->
          <a @click="goDown(props)">{{ props.row.otxtFlNm }}</a>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'tlno'">
          <b-badge :variant="statusVariant(props.row.tlno)">
            {{ props.row.tlno }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'hnetApiRspCd'">
          <b-badge
            v-if="props.row.hnetApiRspCd === null"
            style="border-radius:50%;"
          >
            &ensp;
          </b-badge>
          <b-badge
            v-if="props.row.hnetApiRspCd === '00'"
            variant="success"
            style="border-radius:50%;"
          >
            &ensp;
          </b-badge>
          <b-badge
            v-if="props.row.hnetApiRspCd === '01'"
            variant="danger"
            style="border-radius:50%;"
          >
            &ensp;
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'edt'">
          <b-button size="sm" @click="goUpd(props)">수정</b-button>
        </span>

        <span v-else-if="props.column.field === 'hst'">
          <b-button size="sm" @click="goHst(props)">내역보기</b-button>
        </span>

        <!-- usConnHst -->
        <span
          v-else-if="
            props.column.field === 'mnuFncCd' && props.row.mnuFncCd === 'L'
          "
        >
          로그인
        </span>
        <span
          v-else-if="
            props.column.field === 'mnuFncCd' && props.row.mnuFncCd === 'C'
          "
        >
          데이터 입력
        </span>
        <span
          v-else-if="
            props.column.field === 'mnuFncCd' && props.row.mnuFncCd === 'R'
          "
        >
          접속
        </span>
        <span
          v-else-if="
            props.column.field === 'mnuFncCd' && props.row.mnuFncCd === 'U'
          "
        >
          데이터 수정
        </span>
        <span
          v-else-if="
            props.column.field === 'mnuFncCd' && props.row.mnuFncCd === 'D'
          "
        >
          데이터 삭제
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap mb-0 mt-1">
          <div class="d-flex align-items-center">
            <div v-if="btnYn === true" class="btnGroup">
              <BButton variant="danger" @click="delClick">
                선택삭제
              </BButton>
              <BButton variant="danger" class="ml-1" @click="locklClick" v-if="navName === 'adm'">
                선택잠금
              </BButton>
              <BButton variant="success" class="ml-1" @click="approveClick" v-if="navName === 'adm'">
                선택승인
              </BButton>
            </div>
          </div>
          <div>
            <b-pagination
              size="lg"
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="
                (value) =>
                  pageChange({
                    pageNum: value,
                    pageSize: pageLength,
                    totalRows: props.total,
                    searchTerm: searchTerm,
                    stndYm: stndYm,
                  })
              "
            >
              <template>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
          <div class="d-flex align-items-center ">
            <div v-if="btnYn === true">
              <BButton variant="primary" @click="goRgs">
                등록
              </BButton>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination, BButton, BBadge } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { utils } from "@/devUtils/commUtils";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    BBadge,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    selectYn: {
      type: Object,
      required: true,
    },
    btnYn: {
      type: Boolean,
      required: true,
    },
    searchTerm: {
      type: String,
      required: false,
    },
    totalRows: {
      type: Number,
      required: false,
    },
    stndYm: {
      type: String,
      required: false,
    },
    schOption: {
      type: String,
      required: false,
    },
    usYn: {
      type: String,
      required: false,
    },
    sbdId: {
      type: String,
      required: false,
    },
    summary: {
      type: String,
      required: false,
      default: '',
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      checkboxRows: [],
      tlno: [
        {
          1: "link",
          2: "job",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      tbTitle : "표 제목 : ",
      tbSummary : "표 설명 : ",
      isLoading : false,
      nIntervId : null
    };
  },
  computed: {
    selectedData() {
      // 가변적으로 해결하고싶음...
      let selectedData = null;
      if (this.navName === "adm") {
        selectedData = this.$store.state.adm.selectedData;
      } else if (this.navName === "set") {
        selectedData = this.$store.state.set.selectedData;
      } else if (this.navName === "sysSet") {
        selectedData = this.$store.state.sysSet.selectedData;
      } else if (this.navName === "usrAdm") {
        selectedData = this.$store.state.usrAdm.selectedData;
      }
      
      return selectedData;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        link: "light-primary",
        job: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
	created() {
		// 경로 /adm/adm/
		this.navPath = this.$route.fullPath.substring(
			this.$route.fullPath.lastIndexOf("/") + 1,
			this.$route.fullPath.indexOf("/",this.$route.fullPath.lastIndexOf("/") + 1)
		);
   // 현재 메뉴 이름 adm
   // this.navName = this.navPath.split("/");
    this.navName = this.$route.fullPath.split("/");
    this.navName = this.navName[2];
  },
  methods: {
    async pageChange(pageInfo) {
      let navName = this.navName.toUpperCase();
      const scrnPth = this.navPath.split("/");
      const chkHst = this.$route.fullPath.split("/");

      if (navName === "ADMROL") {
        navName = "ADM_ROL";
      }else if (navName === "BOARDNOTICE") {
        navName = "BOARD_NOTICE";
      }else if (navName === "USRADM") {
        navName = "USR";
      }else if (navName === "HNETCO") {
        navName = "H_NET_CO";
      }else if (navName === "BATCHHST") {
        navName = "BATCH_HST";
      }else if (navName === "CLDOPRT") {
        navName = "CLD_OPRT";
      }else if (navName === "LNKGSYS") {
        navName = "LNKG_SYS";
      }else if (navName === "SERVICECLU") {
        navName = "SERVICE_CLU";
      }else if (navName === "INIFTRT") {
        navName = "INIF_TRT";
      }

      try {
        if (chkHst[chkHst.length - 1].indexOf("usConnHst") > -1) {
          const mngrId = this.rows[0].mngrId;
          this.$store.dispatch(`${scrnPth[1]}/FETCH_${navName}_HST`, {
            mngrId,
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
          });
        } else if (chkHst[chkHst.length - 1].indexOf("batchHstDtl") > -1) {
          const procNm = this.rows[0].procNm;
          this.$store.dispatch(`${scrnPth[1]}/FETCH_${navName}_DTL`, {
            procNm,
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
          });
        } else if (chkHst[chkHst.length - 1].indexOf("batchHstList") > -1) {
          let param = {
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
            usYn: this.usYn,
            procNm: "",
            podCd: "",
            podHr: "",
          };
          if (this.schOption === "프로시저명") {
            param.procNm = this.searchTerm;
          } else if (this.schOption === "주기코드") {
            param.podCd = this.searchTerm;
          } else if (this.schOption === "주기시간") {
            param.podHr = this.searchTerm;
          }
          this.$store.dispatch(`${scrnPth[1]}/FETCH_${navName}_LIST`, param);
        } else if (chkHst[chkHst.length - 1].indexOf("usrList") > -1) {
          let param = {
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
            sbdId: "",
            usrId: "",
            usrNm: "",
            hshId: "",
          };
          if (this.sbdId != undefined) {
            param.sbdId = this.sbdId;
          }
          if (this.schOption === "이름") {
            param.usrNm = this.searchTerm;
          } else if (this.schOption === "아이디") {
            param.usrId = this.searchTerm;
          } else if (this.schOption === "세대아이디") {
            param.hshId = this.searchTerm;
          }
          this.$store.dispatch("usrAdm/FETCH_USR_LIST", param);
        } else if (chkHst[chkHst.length - 1].indexOf("lnkgSysList") > -1) {
          let param = {
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
            frmNm: "",
            lnkgUrl: "",
          };
          if (this.schOption === "업체명") {
            param.frmNm = this.searchTerm;
          } else if (this.schOption === "연동URL") {
            param.lnkgUrl = this.searchTerm;
          }
          this.$store.dispatch("sysSet/FETCH_LNKG_SYS_LIST", param);
        } else if (chkHst[chkHst.length - 1].indexOf("admList") > -1) {
          let mngrId = '';
          let mngrNm = '';
          if(this.selectedData.schOpt){
            if(this.selectedData.schOpt.value == 'name'){
              mngrNm = pageInfo.searchTerm;
            }else if(this.selectedData.schOpt.value == 'id'){
              mngrId = pageInfo.searchTerm;
            }
          }
          this.$store.dispatch(`adm/FETCH_ADM_LIST`, {
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
            selectedCodeData: this.$store.state.adm.selectedListData,
            mngrId: mngrId,
            mngrNm: mngrNm,
          });
			}else if (navName == "INIF_TRT") {
        this.$store.dispatch(`${scrnPth[1]}/FETCH_${navName}_LIST`, {
	        pageSize: pageInfo.pageSize,
	        pageNum: pageInfo.pageNum,
	        searchTerm: pageInfo.searchTerm,
			    searchField: this.schOption
        });
      }else {
          this.$store.dispatch(`${scrnPth[1]}/FETCH_${navName}_LIST`, {
            pageSize: pageInfo.pageSize,
            pageNum: pageInfo.pageNum,
            stndYm: pageInfo.stndYm,
            selectedCodeData: this.$store.state.adm.selectedListData,
            searchTerm: pageInfo.searchTerm,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onRowClick(params) {
      this.checkboxRows = params;
    },
    delClick() {
      let navName = this.navName.toUpperCase();
      if (navName === "BOARDNOTICE") {
        navName = "BOARD_NOTICE";
      }
      if (navName === "HNETCO") {
        navName = "H_NET_CO";
      }
      if (navName === "SERVICECLU") {
        navName = "SERVICE_CLU";
      }

      if (navName === "CLDOPRT") {
        navName = "CLD_OPRT";
      }

      const scrnPth = this.navPath.split("/");
      if (this.checkboxRows.length === 0) {
        utils.fnAlert(this, "삭제할 데이터를 선택해주세요.");
      } else if (this.checkboxRows.selectedRows.length === 0) {
        utils.fnAlert(this, "삭제할 데이터를 선택해주세요.");
      } else {
        utils.fnConfirm(this, "삭제하시겠습니까?", "").then(async (res) => {
          if (res.value) {
            // 삭제 프로세스 시작
            try {
              if (navName === "BOARD_NOTICE") {
                // 공지사항일때 변수가 달라서 사용
                const natSns = this.checkboxRows.selectedRows.map(
                  (v) => v.natSn
                );
                await this.$store.dispatch(
                  `${scrnPth[1]}/FETCH_${navName}_DELETE`,
                  natSns
                );
                await this.$router.push(`/sysSet/boardNotice/boardNoticeList`);
              } else if (navName === "H_NET_CO") {
                const hnetFrmSns = this.checkboxRows.selectedRows.map(
                  (v) => v.hnetFrmSn
                );
                await this.$store.dispatch(
                  `${scrnPth[1]}/FETCH_${navName}_DELETE`,
                  hnetFrmSns
                );
                await this.$router.push(`/sysSet/hNetCo/hNetCoList`);
              } else if (navName === "SERVICE_CLU") {
                const svcCluSns = this.checkboxRows.selectedRows.map(
                  (v) => v.svcCluSn
                );
                await this.$store.dispatch(
                  `${scrnPth[1]}/FETCH_${navName}_DELETE`,
                  svcCluSns
                );
                await this.$router.push(`/sysSet/serviceClu/serviceCluList`);
              } else if (navName === "CLD_OPRT") {
                const stndYms = this.checkboxRows.selectedRows.map(
                  (v) => v.stndYm
                );
                await this.$store.dispatch(
                  `${scrnPth[1]}/FETCH_${navName}_DELETE`,
                  stndYms
                );
                await this.$router.push(`/report/cldOprt/cldOprtList`);
              } else {
                const mngrIds = this.checkboxRows.selectedRows.map(
                  (v) => v.mngrId
                );
                await this.$store.dispatch(
                  `${scrnPth[1]}/FETCH_${navName}_DELETE`,
                  mngrIds
                );
                await this.$router.push(
                  `/${scrnPth[1]}/${scrnPth[1]}/${scrnPth[1]}List`
                );
              }
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
    },
    locklClick() {
      if (this.checkboxRows.length === 0) {
        utils.fnAlert(this, "잠금 처리할 관리자를 선택해주세요.");
      } else if (this.checkboxRows.selectedRows.length === 0) {
        utils.fnAlert(this, "잠금 처리할 관리자를 선택해주세요.");
      } else {
        utils.fnConfirm(this, "잠금 처리 하시겠습니까?", "").then(async (res) => {
          if (res.value) {
            // 삭제 프로세스 시작
            try {
                const mngrIds = this.checkboxRows.selectedRows.map(
                  (v) => v.mngrId
                );
                await this.$store.dispatch(
                  `adm/FETCH_ADM_LOCK`,
                  mngrIds
                );
                await this.$router.push(
                  `/adm/adm/admList`
                );
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
    },
    approveClick() {
      if (this.checkboxRows.length === 0) {
        utils.fnAlert(this, "승인 처리할 관리자를 선택해주세요.");
      } else if (this.checkboxRows.selectedRows.length === 0) {
        utils.fnAlert(this, "승인 처리할 관리자를 선택해주세요.");
      } else {
        utils.fnConfirm(this, "승인 처리 하시겠습니까?", "").then(async (res) => {
          if (res.value) {
            // 승인 프로세스 시작
            try {
                const mngrIds = this.checkboxRows.selectedRows.map(
                  (v) => v.mngrId
                );
                await this.$store.dispatch(
                  `adm/FETCH_ADM_APPROVE`,
                  mngrIds
                );
                await this.$router.push(
                  `/adm/adm/admList`
                );
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
    },
    goRgs() {
      this.$router.push({ path: `${this.navPath}${this.navName}RgsUpd` });
    },
    goUpd(params) {
      if (this.navName === "usrAdm") {
        this.$router.push({
          path: `${this.navPath}usrUpd`,
          query: { usrId: params.formattedRow.usrId },
        });
      } else {
        this.$router.push({
          path: `${this.navPath}${this.navName}RgsUpd`,
          query: {
            mngrId: params.formattedRow.mngrId,
            rolId: params.formattedRow.rolId,
            pgmDsCd: params.formattedRow.pgmDsCd,
            natSn: params.formattedRow.natSn,
            hnetFrmSn: params.formattedRow.hnetFrmSn,
            stndYm: params.formattedRow.stndYm,
            svcCluSn: params.formattedRow.svcCluSn,
          },
        });
      }
    },
    goDtl(params) {
      if (this.navName != "usConn") {
        this.$router.push({
          path: `${this.navPath}${this.navName}Dtl`,
          query: {
            mngrId: params.formattedRow.mngrId,
            natSn: params.formattedRow.natSn,
            pgmDsCd : params.formattedRow.pgmDsCd,
            hnetFrmSn: params.formattedRow.hnetFrmSn,
          },
        });
      }
    },
    goHst(params) {
      if (this.navName === "batchHst") {
        this.$router.push({
          path: `${this.navPath}${this.navName}Dtl`,
          query: { procNm: params.row.procNm },
        });
      } else {
        this.$router.push({
          path: `${this.navPath}${this.navName}Hst`,
          query: { mngrId: params.row.mngrId },
        });
      }
    },
    goDown(params) {
      this.$store.dispatch("report/FETCH_CLD_OPRT_DOWN", params.row);
      /// reportAxiosCall.fetchCldOprtDown(params.row.stndYm);
    },
    setTableCol(){
      if( document.querySelector(".bordered").size != 0){
        document.querySelector(".bordered").getElementsByTagName("th").forEach(element => {
          element.setAttribute("scope", "col")
        });
        clearInterval(this.nIntervId)
      }
    }
  }, 
  // watch : {
  //   columns (){
  //     // 대체 텍스트 처리 : columns 추가 후 처리
  //     this.tbTitle = "테이블 제목 : "
  //     this.tbTitle += document.querySelector("#contents").textContent.trim();
  //     this.tbSummary = "테이블 설명 : "
  //     this.columns.forEach(element => {
  //       this.tbSummary += element.label + ', '
  //     });
  //     this.tbSummary = this.tbSummary.slice(0, this.tbSummary.length - 2) 
  //     this.tbSummary += " 정보를 제공"
  //   },
  // },
  mounted(){
    // col 세팅을 위한 처리
    this.nIntervId = setInterval(this.setTableCol, 1000);

    /**
     *  웹 접근성
     */
    const tableEl = document.querySelector('.vgt-table');
    tableEl.setAttribute('summary', this.summary);

    const captionEl = document.createElement("caption");
    captionEl.textContent = this.caption;
    tableEl.appendChild(captionEl);
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.thClass {
  font-size: 100%;
  margin-right: 100px;
  margin-bottom: 100px;
  text-align: center;
}
.tdClass {
  font-size: 100%;
  width: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
  text-align: center;
}

.btnGroup button:nth-child(2) {
  margin-left:10px;
}
</style>
