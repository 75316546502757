// state에 선언된 변수에 값을 할당함.
export default {
  SET_COMM_SELECTED_DATA(state, payload) {
    state.commCodeData[payload.key] = payload.data.map((v) => ({
      value: v.value,
      text: v.name,
    }));
    // 공통 셀렉트 전체 추가
    state.commCodeData[payload.key].unshift({
      value: "",
      text: "전체",
    });
  },
  SET_SBD_SELECTED_DATA(state, payload) {
    state.commCodeData[payload.key] = payload.data.map((v) => ({
      value: v.value,
      text: v.name,
    }));
  },
  SET_LHW_NAV_MENU_DATA(state, payload) {
    state.lhwNavMenu = payload;
  },
  SET_ADM_LIST_ROWS_DATA(state, payload) {
    // 가변적(필터)으로 리스트 로드
    state.tabled.rows = payload.map((v) => ({
      mngrNm: v.mngrNm,
      mngrId: v.mngrId,
      grpNm: v.grpNm,
      sbdNm: v.sbdNm,
      frmNm: v.frmNm,
      rolNm: v.rolNm,
      mngrSsNm : v.mngrSsNm,
    }));
    state.tabled.totalRows = payload[0].totCnt;
  },
  SET_MNGR_ID_INFO_RESET(state) {
    state.mngrIdInfo = {};
  },
  SET_MNGR_ID_INFO_DATA(state, payload) {
    state.mngrIdInfo = payload
    // state.mngrIdInfo.mngrId = payload.mngrId;
    // state.mngrIdInfo.rolId = payload.rolId;
    // state.mngrIdInfo.mngrPwd = payload.mngrPwd;
    // state.mngrIdInfo.mngrNm = payload.mngrNm;
    // state.mngrIdInfo.mngrMblTlno = payload.mngrMblTlno;
    // state.mngrIdInfo.mngrTlno = payload.mngrTlno;
    // state.mngrIdInfo.mngrEmlAdr = payload.mngrEmlAdr;
    // state.mngrIdInfo.mngrSsCd = payload.mngrSsCd;
    // state.mngrIdInfo.emlRcvYn = payload.emlRcvYn;
    // state.mngrIdInfo.grpSn = payload.grpSn;
    // state.mngrIdInfo.widSn = payload.widSn;
    // state.mngrIdInfo.sbdId = payload.sbdId;
    // state.mngrIdInfo.mngrPstnNm = payload.mngrPstnNm;
    // state.mngrIdInfo.mngrDtsNm = payload.mngrDtsNm;
    // state.mngrIdInfo.frmSn = payload.frmSn;
    // state.mngrIdInfo.rolNm = payload.rolNm;
    // state.mngrIdInfo.frmNm = payload.frmNm;
    // state.mngrIdInfo.grpNm = payload.grpNm;
    // state.mngrIdInfo.sbdNm = payload.sbdNm;
    // state.mngrIdInfo.widNm = payload.widNm;
  },
  SET_MNGR_ID_INFO_UPDATE_DATA(state, payload) {
    state.result = payload;
  },
  SET_MNGR_ID_INFO_SAVE_DATA(state, payload) {
    state.result = payload;
  },
  SET_MNGR_ID_INFO_DELETE_DATA(state, payload) {
    state.result = payload;
  },
  SET_MNGR_ID_INFO_LOCK_DATA(state, payload) {
    state.result = payload;
  },
  SET_MNGR_ID_INFO_APPROVE_DATA(state, payload) {
    state.result = payload;
  },
  SET_US_CONN_LIST_COLUMN_DATA(state) {
    state.tabled.columns = [];
    state.tabled.columns.push(
      {
        label: "이름",
        field: "nm",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "아이디",
        field: "id",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "지역",
        field: "area",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "단지명",
        field: "sbd",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "권한",
        field: "agt",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "전화번호",
        field: "time",
        thClass: "thClass",
        tdClass: "tdClass",
      },
      {
        label: "이메일",
        field: "eml",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
  },

  SET_US_CONN_LIST_ROWS_DATA(state, payload) {
    state.tabled.rows = [];
    state.tabled.rows = payload.map((v) => ({
      nm: v.user,
      id: v.id,
      area: v.type,
      sbd: v.comments_count,
      agt: v.points,
      time: v.time,
      eml: v.domain,
    }));
  },
  SET_ADM_ROL_COLUMNS_DATA(state) {
    state.rolTabled.columns = [];
    state.rolTabled.columns.push(
      {
        label: "관리자 아이디",
        field: "rolId",
        thClass: "thClass p30",
        tdClass: "tdClass p30",
      },
      {
        label: "역할명",
        field: "rolNm",
        thClass: "thClass p30",
        tdClass: "tdClass p30",
      },
      {
        label: "설명",
        field: "rolDesc",
        thClass: "thClass p30",
        tdClass: "tdClass p30",
      },
      {
        label: "수정",
        field: "edt",
        thClass: "thClass",
        tdClass: "tdClass",
      }
    );
  },
  SET_ADM_ROL_LIST_ROWS_DATA(state, payload) {
    state.rolTabled.rows = [];
    state.rolTabled.rows = payload.map((v) => ({
      rolId: v.rolId,
      rolNm: v.rolNm,
      rolDesc: v.rolDesc,
    }));
    state.rolTabled.totalRows = payload[0].totCnt;
  },
  SET_ADM_ROL_INFO_DATA(state, payload) {
    state.rolIdInfo.rolId = payload.rolId;
    state.rolIdInfo.rolNm = payload.rolNm;
    state.rolIdInfo.rolDesc = payload.rolDesc;
  },
  SET_RESULT(state, payload) {
    state.result = payload;
  },
  SET_TOTCNT(state, payload) {
    state.tabled.totalRows = payload.totCnt;
  },
};
