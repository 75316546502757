import { instanceWithAuth } from './index'

export const admAxiosCall = {
  fetchCmnCodesSelected(payload) {
    let res = null;

    const apiUrls = {
      ROL_ID: 'mngr/comn/role-codes',
      // WID_SN: 'mngr/comn/wid-codes', // API에 없음으로 주석 처리
      GRP_SN: 'mngr/comn/grp-codes',
      SBD_ID: `mngr/comn/sbd-codes?grpSn=${payload.value}`,
      FRM_SN: 'mngr/comn/frm-codes',
      SMAH_SBD_ID: 'mngr/comn/smah-sbd-codes',
    };
    
    if (Object.keys(apiUrls).includes(payload.key)) {
      res = instanceWithAuth.get(apiUrls[payload.key]);
    }
    
    return res;
  },
  fetchLhwNavMenu(rolId) {
    return instanceWithAuth.get(`sysmngr/menuroles/${rolId}`);
  },
  fetchAdmList(payload) {
    return instanceWithAuth.get('mngr/managers', {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        mngrId: payload.mngrId,
        mngrNm: payload.mngrNm,
        rolId: payload.selectedCodeData.rolId,
        sbdId: payload.selectedCodeData.sbdId,
        grpSn: payload.selectedCodeData.grpSn,
        frmSn: payload.selectedCodeData.frmSn,
        // widSn: payload.selectedCodeData.widSn
      }
    })
  },
  fetchMngrIdInfo(mngrId) {
    return instanceWithAuth.post(`mngr/managers/${mngrId}`);
  },
  fetchMngrIdInfoUpdate(mngrIdInfo) {
    mngrIdInfo.updDttm = ""; // Date 타입으로 API에 선언되어 있어 제거. 변환 에러 발생
    return instanceWithAuth.put(`mngr/managers`, mngrIdInfo);
  },
  fetchMngrIdInfoSave(mngrIdInfo) {
    return instanceWithAuth.post(`mngr/managers`, mngrIdInfo);
  },
  fetchMngrIdInfoDelete(mngrIds) {
    return instanceWithAuth.delete(`mngr/managers`, {
      data: {
        mngrIds,
      },
    });
  },
  fetchMngrIdInLock(mngrIds) {
    return instanceWithAuth.put(`mngr/managers-lock`, {
        mngrIds,
    });
  },
  fetchMngrIdInApprove(mngrIds) {
    return instanceWithAuth.put(`mngr/managers-approve`, {
        mngrIds,
    });
  },
  fetchUsConnColumns() {
    return instanceWithAuth.post('list');
  },
  fetchUsConnList() {
    return instanceWithAuth.post('list');
  },
  fetchAdmRolColumns() {
    return instanceWithAuth.get('sysmngr/roles');
  },
  fetchAdmRolList(payload) {
    return instanceWithAuth.get(`sysmngr/roles?pageSize=${payload.pageSize}&pageNum=${payload.pageNum}`);
  },
  fetchAdmRolInfo(payload) {
    return instanceWithAuth.get(`sysmngr/roles/${payload}`);
  },
  fetchAdmRolSave(payload) {
    return instanceWithAuth.put('sysmngr/menuroles', payload);
  },
  fetchMenuList(payload) {
    return instanceWithAuth.get(`sysmngr/menus?pageSize=${payload.pageSize}&pageNum=${payload.pageNum}`);
  },
  fetchMenuDtl(mnuId) {
    return instanceWithAuth.get(`sysmngr/menus/${mnuId}`);
  },
  saveMenuUsyn(payload) {
    return instanceWithAuth.put(`sysmngr/menus`, payload);
  },
}
export default { admAxiosCall };
